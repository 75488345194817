.slide{
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 1040;

    &.toggled{
       display: block;
    }

    
    // @include media-breakpoint-up(md){
    //     background-color: rgba(0,0,0, 0.2);
    // }

    .slide-inner{
        position: relative;
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: white;
        opacity: 1;
        transition: transform 300ms;
        transform: translate3d(-100%, 0, 0);

        &.toggled{
            transform: translate3d(0, 0, 0);
        }
        
        // @include media-breakpoint-up(md){
        //     width: 20%;
        //     max-width: 20%;;
        // }
    }
}