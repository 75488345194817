@use "sass:map";

// @import "./reboot.scss";
// @import "overrides/bootstrap/bootstrap.scss";
// @import "overrides/bootstrap/_variables.scss";

@import "./Bmsview/Treemenu.scss";
@import "./navigation-toggle.scss";
@import "./slide.scss";
	
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

	
	@import "./common.scss";
	@import "./controls/grid.scss";

	
    h1 {
      @apply text-2xl;
    }
    h2 {
      @apply text-2xl;
      @apply font-semibold;
      @apply my-2;
    }
    h4 {
        @apply text-xl;
        @apply font-semibold;
        @apply my-1
	}

	.list-reset {
		list-style: none;
		padding: 0;
	}

	.node-selected * {
		fill: rgba(0,0,255,0.2) !important;
	}
	
	.node-selected {
		outline: 1px solid rgba(0,0,255,0.6) ;
	}
	
	.bg-red {
		background: red;
	}
	
	.bg-gold {
		background: gold;
	}
	
	.btn-primary {
		background-color: #007bff !important;
	}
	
	.btn-secondary { 
		background-color: #6c757d !important;
	}
	
	.btn-success { 
		background-color: #28a745 !important;
	}
	
	.btn-danger {
		background-color: #dc3545 !important;
	}

	// @include tailwind-responsive(list-reset){ list-style: none; padding: 0;}
    /* ... */
  }



// @include tailwind_responsive('h-85v') {
// 	height: 85vh;
// }
