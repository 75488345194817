.navItem{
    @apply text-sm list-reset px-2 py-1 mt-1 cursor-pointer mx-2 rounded-t;
}
.first-gen a:not(.selected){
    @apply text-white no-underline;
}
.second-gen a:not(.selected){
    @apply text-white no-underline;
}
.third-gen a:not(.selected){
    @apply text-white no-underline;
}
.fourth-gen a:not(.selected){
    @apply text-white no-underline;
}
.fifth-gen a:not(.selected){
    @apply text-white no-underline;
}
.sixth-gen a:not(.selected){
    @apply text-white no-underline;
}
.first-gen.selected{
    @apply bg-blue-dark text-white;
}
.second-gen.selected{
    @apply bg-blue text-white;
}
.third-gen.selected{
    @apply text-white bg-blue-light;
}
.fourth-gen.selected{
    a { color: black !important; }
    @apply bg-white/20;
}
.fifth-gen.selected{
    a { color: black !important; }
    @apply bg-white/30;
}
.sixth-gen.selected{
    a { color: black !important; }
    @apply bg-white;
}
.first-gen:hover{
    @apply text-white bg-blue-dark;
}
.second-gen:hover{
    @apply text-white bg-blue;
}
.third-gen:hover{
    @apply text-white bg-blue-light;
}
.fourth-gen:hover{
    a { color: black !important; }
    @apply bg-white;
}
.fifth-gen:hover{
    a { color: black !important; }
    @apply bg-white;
}
.sixth-gen:hover{
    a { color: black !important; }
    @apply bg-white;
}
.m-menuitem{
    a { color: black !important; }
    @apply text-sm font-bold flex-1 list-reset float-left p-3 flex w-full self-end content-center;
}
.m-dropdown{
    @apply rounded-b-sm w-full pl-4 text-sm;
}
.m-menuitem a{
    @apply no-underline text-blue-darkest;
}

@screen md {
    .treemenu {
        .mobile-fix-height{
            max-height: 2.25rem;
            overflow: hidden;
        }

        a{
            line-height: 1.5rem ;
        }
    }
}
