// Navigation trigger

$white: #FFFFFF;

.navigation-trigger {

    cursor: pointer;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  
    &.toggled {
      .navigation-trigger__inner {
        transform: rotate(180deg);
  
        &:before {
          transform: scale(1);
        }
      }
  
      .navigation-trigger__line {
        &:first-child {
          width: 12px;
          transform: translateX(8px) translateY(1px) rotate(45deg);
        }
  
        &:last-child {
          width: 11px;
          transform: translateX(8px) translateY(-1px) rotate(-45deg);
        }
      }
    }
  }
  
  .navigation-trigger__inner,
  .navigation-trigger__line {
    width: 18px;
    transition: all 300ms;
  }
  
  .navigation-trigger__inner {
    position: relative;
  
    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      left: -11px;
      top: -14px;
      background-color: rgba($white, 0.25);
      border-radius: 50%;
      transition: all 300ms;
      transform: scale(0);
    }
  }
  
  .navigation-trigger__line {
    height: 2px;
    background-color: $white;
    display: block;
    position: relative;
  
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }