.shadow-container{
    position: relative;
    width: 100%;
}

.schedule-container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	border: 1px solid #ccc;
	box-sizing: border-box;
	*,
	*:before,
	*:after {
		box-sizing: border-box;
    }
    
    &.shadow{
        position: absolute;
        pointer-events: none;

        .event{
            position: absolute;
			pointer-events: auto;
			z-index: 2;

            .title{
                display: block;
                padding: 0.125rem 0.25rem;
                color: white;
                background-color: rgba(0, 0, 0, 0.5);
                font-size: 0.75rem;
			}
			
			&.removed{
				pointer-events: none;
				z-index: 1;
				background-color: red !important;
				opacity: 0.2;
			}
        }
    }

	.column {
		display: grid;
		.grid-cell {
			border-left: 1px solid #eee;
			border-top: 1px solid #eee;

			height: 1.25rem;

			&.header {
				padding: 0.25rem 0.5rem;
				border-bottom: 1px solid #ccc;
				height: 2rem;
				font-size: 0.85rem;
			}

			&.text-column {
				padding: 0.25rem 0.5rem;
				font-size: 0.75rem;
			}

			&.time-container {
				display: grid;
			}

			.grid-time-cell {
				border-bottom: 1px solid #eee;

				&:hover,
				&.active {
					border-bottom: none;
					background-color: yellow;
				}

				&:last-child {
					border: none;
				}
			}
		}
	}
}
